/* VARIABLES */
:root { 
  --dark: #1d1d1d;
  --middle: #ffffff0c;
  --pop1: #ffdf5d;
  --pop2: #FF8E53;
  --light: #fff;
  --linearGradient: linear-gradient(45deg, #ffdf5d 30%, #FF8E53 90%);
  --boxShadow: 0 0 10px 1px #ffdf5d9f;
  /* f7af88 */
}



/* GENERAL AND TYPOGRAPHY */
body {
  width: 100%;
  height: 100vh;
  background-color: var(--dark);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: var(--light);
}

h1 {
  font-size: 3.5rem;
  font-weight: 700;
}

h2 {
  font-size: 2.5rem;
  font-weight: 600;
}

h3 {
  font-size: 2rem;
  font-weight: 500;
}

h4 { 
  font-size: 1.5rem;
}

p {
  text-align: left;
  line-height: 1.6rem;
}

a { 
  padding-top: 2%;
  color: var(--light);
  text-decoration: none;
}

a:hover { 
  color: var(--pop1);
  transform: scale(1.1);
  cursor: pointer;
}

.btn { 
  width: 100px;
  height: 50px;
  background: var(--pop1);
  color: var(--dark);
  font-weight: 600;
  text-align: center;
  border: none;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.btn:hover { 
  box-shadow: var(--boxShadow);
  cursor: pointer;
}

img { 
  object-fit: contain;
}



/* EVERY PAGE */
.content { 
  margin: 0 2%;
  padding-top: 2%;
  text-align: left;
  display: inline-flex;
  justify-content: space-evenly;
  align-items: center;
}

.contentIndiv {
  width: 40%;
}

.contentPages { 
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentPages h2 { 
  margin-bottom: 2%;
  color: var(--pop1);
}



/* NAVBAR */
header {
  display: flex;
  justify-content: flex-end;
  align-items: center
}

nav {
  width: 100%;
  margin-right: 1%;
  background: var(--dark);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

nav::before {
  width: 100%;
  height: 1px;
  margin: 0px auto;
  background-color: var(--light);
  display: block;
  content: "";
}

.navTab { 
  min-width: fit-content;
  min-height: 42px;
  padding: 2%; 
  text-align: center;
  display: flex;
  flex-direction: column; 
  justify-content: space-between;
}

.navTab span { 
  margin-top: 10%;
}



/* ABOUT */
.aboutImg img { 
  width: 320px;
  border-radius: 200px;
  margin: 1%;
  box-shadow: 0 0 5px 3px #ffdf5d;
}

.emailMeButton{
  padding: 0;
}

.emailMeButtonText {
  padding: 15px 20px;
  color: #1d1d1d;
}

.emailMeButtonText:hover {
  color: #fff;
}



/* PORTFOLIO */
.carouselOfProjects { 
  display: flex;
}

.listedProjects { 
  display: none;
}

.carousel-indicators { 
  padding: 0;
  margin: 0;
  bottom: -8%;
}

div.carousel-indicators button.active { 
  background-color: var(--pop1) !important;
}

.carouselCaption:hover {
  color: var(--pop1);
  cursor: pointer;
}

.projectInfo { 
  line-height: 2.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carousel-control-next, .carousel-control-prev, 
.carousel-control-next, .carousel-control-next { 
  width: 5%;
}




/* CONTACT ME */
.formContainer {
  height: 400px;
  padding: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}





/* FOOTER */
.contactIcons {
  font-size: 2rem;
  margin-bottom: 5%;
}

.contactUlTag::after {
  content: "";
  display: block;
  width: 1px;
  height: 90px;
  margin: 0px auto;
  background-color: var(--light);
}





/* MEDIA QUERIES */
/* 1150 - Between Large Laptop and Laptop */
@media screen and (max-width: 1150px) {
  /* PORTFOLIO */
  .carousel.slide { 
    width: 95% !important; 
  }

  .carouselOfProjects { 
    display: none;
  }

  .listedProjects { 
    width: 80%;
    display: flex;
    flex-direction: column;
  }

  .listedProjectContainer { 
    margin-bottom: 8%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .listedProjectCaption { 
    width: 80%;
    padding: 2%;
    background: #161616;
    display: flex;
    justify-content: center;
  }
}



/* 871 - Between Laptop and Tablet */
@media screen and (max-width: 871px) {
  /* EVERY PAGE */
  .content 
  {
    width: 100%;
    margin: 0% 0% 4% 0%;
    padding-top: 0%;
    flex-direction: column-reverse;
  }

  .contentIndiv { 
    width: 80%;
  }



  /* NAVBAR */
  header {
    font-size: .9rem;
  }

  .navTab { 
    padding: 4%; 
  }


  
  /* ABOUT */
  .contentIndiv h1,
  .contentIndiv h2, 
  .contentIndiv h3 {
    text-align: center;
  }

  .aboutImg { 
    width: 80%;
    display: flex;
    justify-content: center;
  }

  .aboutImg img { 
    width: 30%;
  }



  /* RESUME */
  /* .resumeTitle { 
    margin: 5% 0 0 0;
    border: none;
  }

  .resumeContent { 
    margin: 5% 5% 0 5%;
    padding: 3% 5% 9% 5%;
    border-top: 2px solid var(--pop1);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .resumeSubTitle.reduce {
    width: 80%;
    line-break: normal;
  }

  .resumeUl { 
    width: 100%;
    font-size: 1rem;
  }
  */
} 



/* 571 - Between Tablet and Large Phones */
@media screen and (max-width: 571px) {
  /* EVERY PAGE */
  .contentIndiv { 
    width: 70%;
  }



  /* PORTFOLIO */
  .listedProjects { 
    width: 90%;
  }

  .contentPages h2 { 
    margin-bottom: 5%;
  }  
}




/* 380 - Between Large and Medium Phones */
@media screen and (max-width: 380px) {
  /* GENERAL AND TYPOGRPHY */
  h1,
  h2 { 
    font-size: 2.5rem;
  }
}





/* 320 - Small Phones */
@media screen and (max-width: 320px) {
  /* GENERAL AND TYPOGRAPHY */
  h1, 
  h2, 
  h3 { 
    font-size: 2rem;
  }

  .content, 
  .contentPages { 
    margin: 5% 3%;
  }




  /* ABOUT */
  .aboutImg img { 
    margin: 5%;
  }




  /* PORTFOLIO */
  .contentPages h2 { 
    margin-bottom: 10%;
  } 
  
  


  /* CONTACT ME */
  .formContainer {
    padding: 6%;
  }





  /* FOOTER */
  .contactIcons {
    font-size: 1.8rem;
    margin-bottom: 30%;
  }

}